"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleDecoder = void 0;
var constants_1 = require("../constants");
var utils_1 = require("./utils");
var ModuleDecoder = /** @class */ (function () {
    function ModuleDecoder(provider, useFallbackDecoding) {
        this.provider = provider;
        this.useFallbackDecoding = useFallbackDecoding || true;
    }
    ModuleDecoder.prototype.decodeEthereumTx = function (safe, tx) {
        // TODO: implement
        return undefined;
    };
    ModuleDecoder.prototype.decodeFromEthereumHash = function (safe, txHash) {
        return __awaiter(this, void 0, void 0, function () {
            var ethTx;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("Fallback to transaction decoding");
                        return [4 /*yield*/, this.provider.getTransaction(txHash)];
                    case 1:
                        ethTx = _a.sent();
                        return [2 /*return*/, this.decodeEthereumTx(safe, ethTx)];
                }
            });
        });
    };
    ModuleDecoder.prototype.decodeModuleDetailsEvent = function (event) {
        if (!event)
            return undefined;
        var parsed = constants_1.safeInterface.decodeEventLog("SafeModuleTransaction", event.data, event.topics);
        return {
            to: parsed.to,
            value: parsed.value.toString(),
            data: parsed.data,
            operation: parsed.operation,
        };
    };
    ModuleDecoder.prototype.decodeInternal = function (safe, module, event, success, subLogs, details, parentDecoder) {
        return __awaiter(this, void 0, void 0, function () {
            var decodedTx, id, block, txMeta;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        id = "module_" + event.transactionHash + "_" + event.eventId;
                        decodedTx = this.decodeModuleDetailsEvent(details);
                        if (!(!decodedTx && this.useFallbackDecoding)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.decodeFromEthereumHash(safe, event.transactionHash)];
                    case 1:
                        decodedTx = _b.sent();
                        _b.label = 2;
                    case 2: return [4 /*yield*/, this.provider.getBlock(event.blockHash)];
                    case 3:
                        block = _b.sent();
                        _a = {
                            type: "module_transaction",
                            id: id,
                            block: block.number,
                            timestamp: block.timestamp
                        };
                        return [4 /*yield*/, utils_1.mapEvents(parentDecoder, subLogs)];
                    case 4:
                        txMeta = (_a.logs = _b.sent(),
                            _a.txHash = event.transactionHash,
                            _a.module = module,
                            _a.success = success,
                            _a.details = decodedTx,
                            _a);
                        return [2 /*return*/, txMeta];
                }
            });
        });
    };
    ModuleDecoder.prototype.decode = function (event, subEvents, detailEvent, parentDecoder) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, eventParams, eventParams;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = event.topics[0];
                        switch (_a) {
                            case constants_1.moduleSuccessTopic: return [3 /*break*/, 1];
                            case constants_1.moduleFailureTopic: return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 5];
                    case 1:
                        eventParams = constants_1.safeInterface.decodeEventLog("ExecutionFromModuleSuccess", event.data, event.topics);
                        return [4 /*yield*/, this.decodeInternal(event.address, eventParams.module, event, true, subEvents, detailEvent, parentDecoder)];
                    case 2: return [2 /*return*/, _b.sent()];
                    case 3:
                        eventParams = constants_1.safeInterface.decodeEventLog("ExecutionFromModuleFailure", event.data, event.topics);
                        return [4 /*yield*/, this.decodeInternal(event.address, eventParams.module, event, false, subEvents, detailEvent, parentDecoder)];
                    case 4: return [2 /*return*/, _b.sent()];
                    case 5:
                        {
                            return [2 /*return*/, undefined];
                        }
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return ModuleDecoder;
}());
exports.ModuleDecoder = ModuleDecoder;
