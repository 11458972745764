export const chainIdToNetwork: Record<number, string> = {
    1: "MAINNET",
    2: "MORDEN",
    3: "ROPSTEN",
    4: "RINKEBY",
    5: "GOERLI",
    42: "KOVAN",
    56: "BSC",
    100: "XDAI",
    137: "POLYGON",
    246: "ENERGY_WEB_CHAIN",
    4447: "LOCAL",
    73799: "VOLTA",
}