"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.detailsTopics = exports.parentTopics = exports.etherReceivedTopic = exports.moduleDetailsTopic = exports.moduleFailureTopic = exports.moduleSuccessTopic = exports.multisigDetailsTopic = exports.failureTopic = exports.successTopic = exports.safeInterface = exports.safeAbi = exports.transferTopic = exports.erc721Interface = exports.erc20OldInterface = exports.erc20Interface = exports.erc721InterfaceDefinition = exports.erc20OldInterfaceDefinition = exports.erc20InterfaceDefinition = void 0;
var safe_deployments_1 = require("@gnosis.pm/safe-deployments");
var ethers_1 = require("ethers");
exports.erc20InterfaceDefinition = [
    "event Transfer(address indexed from, address indexed to, uint256 amount)"
];
exports.erc20OldInterfaceDefinition = [
    "event Transfer(address indexed from, address to, uint256 amount)"
];
exports.erc721InterfaceDefinition = [
    "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)"
];
exports.erc20Interface = new ethers_1.ethers.utils.Interface(exports.erc20InterfaceDefinition);
exports.erc20OldInterface = new ethers_1.ethers.utils.Interface(exports.erc20OldInterfaceDefinition);
exports.erc721Interface = new ethers_1.ethers.utils.Interface(exports.erc721InterfaceDefinition);
// The same for all interfaces as `indexed` has no impact on the topic
exports.transferTopic = exports.erc20Interface.getEventTopic("Transfer");
exports.safeAbi = safe_deployments_1.getSafeL2SingletonDeployment({ released: undefined }).abi;
exports.safeInterface = new ethers_1.ethers.utils.Interface(exports.safeAbi);
exports.successTopic = exports.safeInterface.getEventTopic("ExecutionSuccess");
exports.failureTopic = exports.safeInterface.getEventTopic("ExecutionFailure");
exports.multisigDetailsTopic = exports.safeInterface.getEventTopic("SafeMultiSigTransaction");
exports.moduleSuccessTopic = exports.safeInterface.getEventTopic("ExecutionFromModuleSuccess");
exports.moduleFailureTopic = exports.safeInterface.getEventTopic("ExecutionFromModuleFailure");
exports.moduleDetailsTopic = exports.safeInterface.getEventTopic("SafeModuleTransaction");
exports.etherReceivedTopic = exports.safeInterface.getEventTopic("SafeReceived");
// Failure topics cannot generate sub events, we should remove them in the future
exports.parentTopics = [exports.successTopic, exports.moduleSuccessTopic, exports.failureTopic, exports.moduleFailureTopic];
exports.detailsTopics = [exports.multisigDetailsTopic, exports.moduleDetailsTopic];
