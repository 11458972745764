"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EthersParser = void 0;
var constants_1 = require("../constants");
var module_1 = require("./module");
var multisig_1 = require("./multisig");
var settings_1 = require("./settings");
var transfers_1 = require("./transfers");
var EthersParser = /** @class */ (function () {
    function EthersParser(provider) {
        this.groupIdFromEvent = function (event) { return "" + event.transactionHash; };
        this.provider = provider;
        this.decoders = [
            new multisig_1.MultisigDecoder(provider),
            new module_1.ModuleDecoder(provider),
            new transfers_1.TransferDecoder(provider),
            new settings_1.SettingsDecoder(provider)
        ];
    }
    EthersParser.prototype.updateGroupedLogs = function (groups, detailsCandidate, parentCandidate, currentChildren) {
        if (parentCandidate) {
            groups.push({
                parent: parentCandidate,
                details: detailsCandidate,
                children: currentChildren
            });
        }
        else if (currentChildren.length > 0) {
            groups.push.apply(groups, currentChildren.map(function (log) { return { parent: log, children: [] }; }));
        }
    };
    EthersParser.prototype.group = function (events) {
        return __awaiter(this, void 0, void 0, function () {
            var out, currentChildren, detailsCandidates, parentCandidate, currentGroupId, _i, events_1, event, groupId, isParentCandidate, isDetailsCandidate;
            return __generator(this, function (_a) {
                out = [];
                currentChildren = [];
                detailsCandidates = [];
                currentGroupId = undefined;
                for (_i = 0, events_1 = events; _i < events_1.length; _i++) {
                    event = events_1[_i];
                    groupId = this.groupIdFromEvent(event);
                    isParentCandidate = constants_1.parentTopics.indexOf(event.topics[0]) >= 0;
                    isDetailsCandidate = constants_1.detailsTopics.indexOf(event.topics[0]) >= 0;
                    if (currentGroupId !== groupId || (isParentCandidate && parentCandidate)) {
                        this.updateGroupedLogs(out, detailsCandidates.pop(), parentCandidate, currentChildren);
                        parentCandidate = undefined;
                        detailsCandidates = [];
                        currentChildren = [];
                        currentGroupId = undefined;
                    }
                    if (!currentGroupId)
                        currentGroupId = groupId;
                    if (isParentCandidate) {
                        parentCandidate = event;
                    }
                    else if (isDetailsCandidate) {
                        detailsCandidates.push(event);
                    }
                    else {
                        currentChildren.push(event);
                    }
                }
                this.updateGroupedLogs(out, detailsCandidates.pop(), parentCandidate, currentChildren);
                return [2 /*return*/, out];
            });
        });
    };
    EthersParser.prototype.decode = function (event, subEvents, detailEvent) {
        return __awaiter(this, void 0, void 0, function () {
            var _i, _a, decoder, interaction;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _i = 0, _a = this.decoders;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        decoder = _a[_i];
                        return [4 /*yield*/, decoder.decode(event, subEvents, detailEvent, this)];
                    case 2:
                        interaction = _b.sent();
                        if (interaction)
                            return [2 /*return*/, interaction];
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    EthersParser.prototype.parse = function (events) {
        return __awaiter(this, void 0, void 0, function () {
            var groups, inter;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.group(events)];
                    case 1:
                        groups = _a.sent();
                        inter = groups.map(function (_a) {
                            var parent = _a.parent, children = _a.children, details = _a.details;
                            return _this.decode(parent, children, details);
                        });
                        return [4 /*yield*/, Promise.all(inter)];
                    case 2: return [2 /*return*/, (_a.sent()).filter(function (e) { return e !== undefined; })];
                }
            });
        });
    };
    return EthersParser;
}());
exports.EthersParser = EthersParser;
