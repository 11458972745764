"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EthersLoader = void 0;
__exportStar(require("./sources"), exports);
var isOlder = function (compare, base) {
    if (compare === undefined)
        return false;
    if (base === undefined)
        return true;
    if (compare.blockNumber != base.blockNumber)
        return compare.blockNumber < base.blockNumber;
    if (compare.transactionIndex != base.transactionIndex)
        return compare.transactionIndex < base.transactionIndex;
    if (compare.logIndex != base.logIndex)
        return compare.logIndex < base.logIndex;
    return false; // Equal defaults to false
};
var EthersLoader = /** @class */ (function () {
    function EthersLoader(provider, sources) {
        this.provider = provider;
        this.sources = sources;
    }
    EthersLoader.prototype.loadChainId = function () {
        return __awaiter(this, void 0, void 0, function () {
            var network;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.provider.getNetwork()];
                    case 1:
                        network = _a.sent();
                        return [2 /*return*/, network.chainId];
                }
            });
        });
    };
    EthersLoader.prototype.loadCurrentBlock = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.provider.getBlockNumber()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    EthersLoader.prototype.merge = function () {
        var loaders = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            loaders[_i] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var loaderCount, logResults, currentLogIndex, i, out, runs, resultIndex, nextLog, i, candidate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loaderCount = loaders.length;
                        if (loaderCount == 0)
                            return [2 /*return*/, []];
                        return [4 /*yield*/, Promise.all(loaders)];
                    case 1:
                        logResults = _a.sent();
                        if (loaderCount == 1)
                            return [2 /*return*/, logResults[0]];
                        currentLogIndex = new Array(loaderCount).fill(0);
                        for (i = 0; i < loaderCount; i++)
                            currentLogIndex[i] = 0;
                        out = [];
                        runs = 0;
                        // Panic check against endless loop (10k is max amount of events, per loader)
                        while (runs < 10000 * loaderCount) {
                            resultIndex = 0;
                            nextLog = logResults[0][currentLogIndex[0]];
                            for (i = 1; i < loaderCount; i++) {
                                candidate = logResults[i][currentLogIndex[i]];
                                if (isOlder(candidate, nextLog)) {
                                    resultIndex = i;
                                    nextLog = candidate;
                                }
                            }
                            currentLogIndex[resultIndex]++;
                            if (nextLog)
                                out.push(nextLog);
                            else
                                break;
                            runs++;
                        }
                        return [2 /*return*/, out];
                }
            });
        });
    };
    EthersLoader.prototype.toEvent = function (log, safe) {
        return __assign(__assign({}, log), { eventId: log.logIndex.toString(), account: safe });
    };
    EthersLoader.prototype.loadEvents = function (from, to, safe) {
        return __awaiter(this, void 0, void 0, function () {
            var logs;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.merge.apply(this, this.sources.map(function (l) { return l.loadEvents(from, to, safe); }))];
                    case 1:
                        logs = _a.sent();
                        return [2 /*return*/, logs.reverse().map(function (e) { return _this.toEvent(e, safe); })];
                }
            });
        });
    };
    return EthersLoader;
}());
exports.EthersLoader = EthersLoader;
