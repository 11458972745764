"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutgoingTransferEventSource = exports.IncomingTransferEventSource = exports.IncomingEthEventSource = exports.SettingsChangeEventSource = exports.ModuleTransactionEventSource = exports.MultisigTransactionEventSource = exports.StaticTopicEventSource = void 0;
var ethers_1 = require("ethers");
var constants_1 = require("../constants");
var BaseEventSource = /** @class */ (function () {
    function BaseEventSource(name, provider) {
        this.provider = provider;
        this.name = name;
    }
    BaseEventSource.prototype.loadEvents = function (fromBlock, toBlock, address) {
        return __awaiter(this, void 0, void 0, function () {
            var filter;
            return __generator(this, function (_a) {
                filter = this.buildFilter(fromBlock, toBlock, address);
                return [2 /*return*/, this.provider.getLogs(filter).then(function (e) {
                        //console.debug(this.name, "source loaded", e.length, "events")
                        return e;
                    })];
            });
        });
    };
    return BaseEventSource;
}());
var StaticTopicEventSource = /** @class */ (function (_super) {
    __extends(StaticTopicEventSource, _super);
    function StaticTopicEventSource(name, topics, provider) {
        var _this = _super.call(this, name, provider) || this;
        _this.topics = topics;
        return _this;
    }
    StaticTopicEventSource.prototype.buildFilter = function (fromBlock, toBlock, address) {
        return {
            topics: [this.topics],
            address: address,
            fromBlock: fromBlock,
            toBlock: toBlock,
        };
    };
    return StaticTopicEventSource;
}(BaseEventSource));
exports.StaticTopicEventSource = StaticTopicEventSource;
var MultisigTransactionEventSource = /** @class */ (function (_super) {
    __extends(MultisigTransactionEventSource, _super);
    function MultisigTransactionEventSource(provider) {
        return _super.call(this, "MultiSig", [constants_1.successTopic, constants_1.failureTopic, constants_1.multisigDetailsTopic], provider) || this;
    }
    return MultisigTransactionEventSource;
}(StaticTopicEventSource));
exports.MultisigTransactionEventSource = MultisigTransactionEventSource;
var ModuleTransactionEventSource = /** @class */ (function (_super) {
    __extends(ModuleTransactionEventSource, _super);
    function ModuleTransactionEventSource(provider) {
        return _super.call(this, "Module", [constants_1.moduleSuccessTopic, constants_1.moduleFailureTopic, constants_1.moduleDetailsTopic], provider) || this;
    }
    return ModuleTransactionEventSource;
}(StaticTopicEventSource));
exports.ModuleTransactionEventSource = ModuleTransactionEventSource;
var SettingsChangeEventSource = /** @class */ (function (_super) {
    __extends(SettingsChangeEventSource, _super);
    function SettingsChangeEventSource(provider) {
        return _super.call(this, "Settings", [
            "SafeSetup",
            "AddedOwner",
            "RemovedOwner",
            "ChangedThreshold",
            "EnabledModule",
            "DisabledModule",
            "ChangedFallbackHandler",
            "ChangedGuard",
        ].map(function (name) { return constants_1.safeInterface.getEventTopic(name); }), provider) || this;
    }
    return SettingsChangeEventSource;
}(StaticTopicEventSource));
exports.SettingsChangeEventSource = SettingsChangeEventSource;
var IncomingEthEventSource = /** @class */ (function (_super) {
    __extends(IncomingEthEventSource, _super);
    function IncomingEthEventSource(provider) {
        return _super.call(this, "Incoming Ether", [constants_1.etherReceivedTopic], provider) || this;
    }
    return IncomingEthEventSource;
}(StaticTopicEventSource));
exports.IncomingEthEventSource = IncomingEthEventSource;
var IncomingTransferEventSource = /** @class */ (function (_super) {
    __extends(IncomingTransferEventSource, _super);
    function IncomingTransferEventSource(provider) {
        return _super.call(this, "Incoming transfers", provider) || this;
    }
    IncomingTransferEventSource.prototype.buildFilter = function (fromBlock, toBlock, address) {
        // Transfer events are only supported if an account address is provided
        if (!address)
            throw Error("Transfer event source requires an address");
        return {
            topics: [[constants_1.transferTopic], [ethers_1.ethers.utils.defaultAbiCoder.encode(["address"], [address])]],
            fromBlock: fromBlock,
            toBlock: toBlock,
        };
    };
    return IncomingTransferEventSource;
}(BaseEventSource));
exports.IncomingTransferEventSource = IncomingTransferEventSource;
var OutgoingTransferEventSource = /** @class */ (function (_super) {
    __extends(OutgoingTransferEventSource, _super);
    function OutgoingTransferEventSource(provider) {
        return _super.call(this, "Incoming transfers", provider) || this;
    }
    OutgoingTransferEventSource.prototype.buildFilter = function (fromBlock, toBlock, address) {
        // Transfer events are only supported if an account address is provided
        if (!address)
            throw Error("Transfer event source requires an address");
        return {
            topics: [[constants_1.transferTopic], null, [ethers_1.ethers.utils.defaultAbiCoder.encode(["address"], [address])]],
            fromBlock: fromBlock,
            toBlock: toBlock,
        };
    };
    return OutgoingTransferEventSource;
}(BaseEventSource));
exports.OutgoingTransferEventSource = OutgoingTransferEventSource;
