"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultisigDecoder = void 0;
var ethers_1 = require("ethers");
var safe_contracts_1 = require("@gnosis.pm/safe-contracts");
var constants_1 = require("../constants");
var utils_1 = require("./utils");
var NonceMapper = /** @class */ (function () {
    function NonceMapper(provider) {
        this.provider = provider;
    }
    NonceMapper.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.provider.getNetwork()];
                    case 1:
                        _a.chainId = (_b.sent()).chainId;
                        return [2 /*return*/];
                }
            });
        });
    };
    NonceMapper.prototype.calculateHash111 = function (tx, nonce) {
        return ethers_1.ethers.utils._TypedDataEncoder.hash({ verifyingContract: tx.safe }, safe_contracts_1.EIP712_SAFE_TX_TYPE, __assign(__assign({}, tx), { nonce: nonce }));
    };
    NonceMapper.prototype.map = function (expectedHash, tx) {
        return __awaiter(this, void 0, void 0, function () {
            var safe, currentNonce, nonce;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.chainId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.init()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        safe = new ethers_1.ethers.Contract(tx.safe, constants_1.safeAbi, this.provider);
                        return [4 /*yield*/, safe.nonce()];
                    case 3:
                        currentNonce = (_a.sent()).toNumber();
                        for (nonce = currentNonce; nonce >= 0; nonce--) {
                            if (this.calculateHash111(tx, nonce) === expectedHash)
                                return [2 /*return*/, nonce];
                            if (safe_contracts_1.calculateSafeTransactionHash(safe, __assign(__assign({}, tx), { nonce: nonce }), this.chainId) === expectedHash)
                                return [2 /*return*/, nonce];
                        }
                        return [2 /*return*/, -1];
                }
            });
        });
    };
    return NonceMapper;
}());
var MultisigDecoder = /** @class */ (function () {
    function MultisigDecoder(provider, useFallbackDecoding) {
        this.provider = provider;
        this.nonceMapper = new NonceMapper(provider);
        this.useFallbackDecoding = useFallbackDecoding || true;
    }
    MultisigDecoder.prototype.decodeEthereumTx = function (safe, tx) {
        try {
            if (tx.to !== safe) {
                console.log("Transaction is not to the Safe");
                return undefined;
            }
            var result = constants_1.safeInterface.decodeFunctionData("execTransaction", tx.data);
            return {
                safe: safe,
                to: result.to,
                value: result.value.toString(),
                data: result.data,
                operation: result.operation,
                safeTxGas: result.safeTxGas.toString(),
                baseGas: result.baseGas.toString(),
                gasPrice: result.gasPrice.toString(),
                gasToken: result.gasToken,
                refundReceiver: result.refundReceiver,
                signatures: result.signatures,
            };
        }
        catch (e) {
            // TODO: try to decode other ways 
            console.log("Unknown function", tx.data.slice(0, 10));
            return undefined;
        }
    };
    MultisigDecoder.prototype.decodeFromEthereumHash = function (safe, safeTxHash, txHash) {
        return __awaiter(this, void 0, void 0, function () {
            var ethTx, decodedTx, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        console.log("Fallback to transaction decoding");
                        return [4 /*yield*/, this.provider.getTransaction(txHash)];
                    case 1:
                        ethTx = _c.sent();
                        decodedTx = this.decodeEthereumTx(safe, ethTx);
                        if (!decodedTx)
                            return [2 /*return*/, undefined];
                        _a = [__assign({}, decodedTx)];
                        _b = {};
                        return [4 /*yield*/, this.nonceMapper.map(safeTxHash, decodedTx)];
                    case 2: return [2 /*return*/, __assign.apply(void 0, _a.concat([(_b.nonce = _c.sent(), _b)]))];
                }
            });
        });
    };
    MultisigDecoder.prototype.decodeMultisigDetailsEvent = function (event) {
        if (!event)
            return undefined;
        var parsed = constants_1.safeInterface.decodeEventLog("SafeMultiSigTransaction", event.data, event.topics);
        return {
            to: parsed.to,
            value: parsed.value.toString(),
            data: parsed.data,
            operation: parsed.operation,
            safeTxGas: parsed.safeTxGas.toString(),
            baseGas: parsed.baseGas.toString(),
            gasPrice: parsed.gasPrice.toString(),
            gasToken: parsed.gasToken,
            refundReceiver: parsed.refundReceiver,
            signatures: parsed.signatures,
            nonce: ethers_1.BigNumber.from(parsed.additionalInfo.slice(0, 66)).toNumber()
        };
    };
    MultisigDecoder.prototype.decodeInternal = function (safe, event, safeTxHash, success, subLogs, details, parentDecoder) {
        return __awaiter(this, void 0, void 0, function () {
            var id, decodedTx, block, txMeta;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        id = "multisig_" + safeTxHash;
                        decodedTx = this.decodeMultisigDetailsEvent(details);
                        if (!(!decodedTx && this.useFallbackDecoding)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.decodeFromEthereumHash(safe, safeTxHash, event.transactionHash)];
                    case 1:
                        decodedTx = _b.sent();
                        _b.label = 2;
                    case 2: return [4 /*yield*/, this.provider.getBlock(event.blockHash)];
                    case 3:
                        block = _b.sent();
                        _a = {
                            type: "multisig_transaction",
                            id: id,
                            block: block.number,
                            timestamp: block.timestamp
                        };
                        return [4 /*yield*/, utils_1.mapEvents(parentDecoder, subLogs)];
                    case 4:
                        txMeta = (_a.logs = _b.sent(),
                            _a.txHash = event.transactionHash,
                            _a.safeTxHash = safeTxHash,
                            _a.success = success,
                            _a.details = decodedTx,
                            _a);
                        return [2 /*return*/, txMeta];
                }
            });
        });
    };
    MultisigDecoder.prototype.decode = function (event, subEvents, detailEvent, parentDecoder) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, eventParams, eventParams;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = event.topics[0];
                        switch (_a) {
                            case constants_1.successTopic: return [3 /*break*/, 1];
                            case constants_1.failureTopic: return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 5];
                    case 1:
                        eventParams = constants_1.safeInterface.decodeEventLog("ExecutionSuccess", event.data, event.topics);
                        return [4 /*yield*/, this.decodeInternal(event.address, event, eventParams.txHash, true, subEvents, detailEvent, parentDecoder)];
                    case 2: return [2 /*return*/, _b.sent()];
                    case 3:
                        eventParams = constants_1.safeInterface.decodeEventLog("ExecutionFailure", event.data, event.topics);
                        return [4 /*yield*/, this.decodeInternal(event.address, event, eventParams.txHash, false, subEvents, detailEvent, parentDecoder)];
                    case 4: return [2 /*return*/, _b.sent()];
                    case 5:
                        {
                            return [2 /*return*/, undefined];
                        }
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return MultisigDecoder;
}());
exports.MultisigDecoder = MultisigDecoder;
