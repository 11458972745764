"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsDecoder = void 0;
var constants_1 = require("../constants");
var setupTopic = constants_1.safeInterface.getEventTopic("SafeSetup");
var addedOwnerTopic = constants_1.safeInterface.getEventTopic("AddedOwner");
var removedOwnerTopic = constants_1.safeInterface.getEventTopic("RemovedOwner");
var changeThresholdTopic = constants_1.safeInterface.getEventTopic("ChangedThreshold");
var enabledModuleTopic = constants_1.safeInterface.getEventTopic("EnabledModule");
var disabledModuledTopic = constants_1.safeInterface.getEventTopic("DisabledModule");
var changeFallbackHandlerTopic = constants_1.safeInterface.getEventTopic("ChangedFallbackHandler");
var changeGuardTopic = constants_1.safeInterface.getEventTopic("ChangedGuard");
var SettingsDecoder = /** @class */ (function () {
    function SettingsDecoder(provider, useFallbackDecoding) {
        this.provider = provider;
        this.useFallbackDecoding = useFallbackDecoding || true;
    }
    SettingsDecoder.prototype.getSettingsDetails = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var eventParams, eventParams, eventParams, eventParams, eventParams, eventParams, eventParams;
            return __generator(this, function (_a) {
                switch (event.topics[0]) {
                    case addedOwnerTopic: {
                        eventParams = constants_1.safeInterface.decodeEventLog("AddedOwner", event.data, event.topics);
                        return [2 /*return*/, {
                                component: 'owners',
                                change: 'added',
                                value: eventParams.owner
                            }];
                    }
                    case removedOwnerTopic: {
                        eventParams = constants_1.safeInterface.decodeEventLog("RemovedOwner", event.data, event.topics);
                        return [2 /*return*/, {
                                component: 'owners',
                                change: 'removed',
                                value: eventParams.owner
                            }];
                    }
                    case changeThresholdTopic: {
                        eventParams = constants_1.safeInterface.decodeEventLog("ChangedThreshold", event.data, event.topics);
                        return [2 /*return*/, {
                                component: 'threshold',
                                change: 'set',
                                value: eventParams.threshold.toString()
                            }];
                    }
                    case enabledModuleTopic: {
                        eventParams = constants_1.safeInterface.decodeEventLog("EnabledModule", event.data, event.topics);
                        return [2 /*return*/, {
                                component: 'modules',
                                change: 'added',
                                value: eventParams.module
                            }];
                    }
                    case disabledModuledTopic: {
                        eventParams = constants_1.safeInterface.decodeEventLog("DisabledModule", event.data, event.topics);
                        return [2 /*return*/, {
                                component: 'modules',
                                change: 'removed',
                                value: eventParams.module
                            }];
                    }
                    case changeFallbackHandlerTopic: {
                        eventParams = constants_1.safeInterface.decodeEventLog("ChangedFallbackHandler", event.data, event.topics);
                        return [2 /*return*/, {
                                component: 'fallbackHandler',
                                change: 'set',
                                value: eventParams.handler
                            }];
                    }
                    case changeGuardTopic: {
                        eventParams = constants_1.safeInterface.decodeEventLog("ChangedGuard", event.data, event.topics);
                        return [2 /*return*/, {
                                component: 'guard',
                                change: 'set',
                                value: eventParams.guard
                            }];
                    }
                    default: {
                        return [2 /*return*/, undefined];
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    SettingsDecoder.prototype.decodeSettingsChange = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var details, block;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSettingsDetails(event)];
                    case 1:
                        details = _a.sent();
                        if (!details)
                            return [2 /*return*/, undefined];
                        return [4 /*yield*/, this.provider.getBlock(event.blockHash)];
                    case 2:
                        block = _a.sent();
                        return [2 /*return*/, __assign({ type: "settings", id: "settings_" + event.transactionHash + "_" + event.eventId, block: block.number, timestamp: block.timestamp, txHash: event.transactionHash }, details)];
                }
            });
        });
    };
    SettingsDecoder.prototype.decode = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, block;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = event.topics[0];
                        switch (_a) {
                            case setupTopic: return [3 /*break*/, 1];
                        }
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.provider.getBlock(event.blockHash)];
                    case 2:
                        block = _b.sent();
                        return [2 /*return*/, {
                                type: "setup",
                                id: "setup_" + event.transactionHash + "_" + event.eventId,
                                block: block.number,
                                timestamp: block.timestamp,
                                txHash: event.transactionHash,
                            }];
                    case 3:
                        {
                            return [2 /*return*/, this.decodeSettingsChange(event)];
                        }
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return SettingsDecoder;
}());
exports.SettingsDecoder = SettingsDecoder;
