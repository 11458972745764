"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SafeIndexer = void 0;
var sleep = function (timeout) { return new Promise(function (cb) { return setTimeout(cb, timeout); }); };
var conditionalSleep = function (timeout) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(timeout && timeout > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, sleep(timeout)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); };
var configDefaults = {
    earliestBlock: 0,
    maxBlocks: 100,
    upToDateTimeout: 10000,
    syncTimeout: 100,
    reverse: false
};
var SafeIndexer = /** @class */ (function () {
    function SafeIndexer(state, loader, parser, callback, config) {
        this.running = true;
        this.paused = false;
        this.instanceId = Math.random().toString(36);
        this.state = state;
        this.loader = loader;
        this.parser = parser;
        this.callback = callback;
        this.config = __assign(__assign({}, configDefaults), config);
    }
    SafeIndexer.prototype.updateConfig = function (update) {
        this.config = __assign(__assign({}, this.config), update);
    };
    SafeIndexer.prototype.postStatusUpdate = function (status) {
        var _a, _b;
        try {
            (_b = (_a = this.callback) === null || _a === void 0 ? void 0 : _a.onStatusUpdate) === null || _b === void 0 ? void 0 : _b.call(_a, status);
        }
        catch (e) {
            console.error(e);
        }
    };
    SafeIndexer.prototype.getCurrentBlockInterval = function (earliestBlock, latestBlock, reverse) {
        console.log(this.state);
        var earliestIndexedBlock = this.state.earliestIndexedBlock;
        var lastIndexedBlock = this.state.lastIndexedBlock;
        var maxBlocks = this.config.maxBlocks;
        if (reverse !== undefined ? reverse : this.config.reverse) {
            if (earliestBlock >= earliestIndexedBlock) {
                return;
            }
            return {
                fromBlock: Math.max(earliestBlock, earliestIndexedBlock - maxBlocks),
                toBlock: earliestIndexedBlock - 1
            };
        }
        else {
            if (latestBlock <= lastIndexedBlock) {
                return;
            }
            return {
                fromBlock: lastIndexedBlock + 1,
                toBlock: Math.min(latestBlock, lastIndexedBlock + maxBlocks)
            };
        }
    };
    SafeIndexer.prototype.ensureBlockDefaults = function (latestBlock) {
        if (this.config.earliestBlock < 0) {
            this.config.earliestBlock = latestBlock;
        }
        if (this.state.earliestIndexedBlock < 0) {
            this.state.earliestIndexedBlock = latestBlock + 1;
        }
        if (this.state.lastIndexedBlock < 0) {
            this.state.lastIndexedBlock = latestBlock - 1;
        }
    };
    SafeIndexer.prototype.watch = function (reverse) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var activeChainId, errorMsg, latestBlock, earliestBlock, blockInterval, fromBlock, toBlock, e_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, this.loader.loadChainId()];
                    case 1:
                        activeChainId = _e.sent();
                        if (this.config.chainId && activeChainId != this.config.chainId) {
                            errorMsg = "Wrong chain! Expected " + this.config.chainId + " got " + activeChainId;
                            (_a = this.config.logger) === null || _a === void 0 ? void 0 : _a.error(errorMsg);
                            this.postStatusUpdate({ type: "aborted", reason: errorMsg, code: 1 });
                            return [2 /*return*/];
                        }
                        _e.label = 2;
                    case 2:
                        if (!this.running) return [3 /*break*/, 14];
                        if (!this.paused) return [3 /*break*/, 4];
                        return [4 /*yield*/, sleep(this.config.syncTimeout)];
                    case 3:
                        _e.sent();
                        return [3 /*break*/, 2];
                    case 4: return [4 /*yield*/, this.loader.loadCurrentBlock()];
                    case 5:
                        latestBlock = _e.sent();
                        this.ensureBlockDefaults(latestBlock);
                        earliestBlock = this.config.earliestBlock;
                        blockInterval = this.getCurrentBlockInterval(earliestBlock, latestBlock, reverse);
                        if (!!blockInterval) return [3 /*break*/, 7];
                        (_b = this.config.logger) === null || _b === void 0 ? void 0 : _b.log(this.instanceId, "Up to date with current block!");
                        this.postStatusUpdate({ type: "up_to_date", latestBlock: latestBlock, earliestBlock: earliestBlock });
                        return [4 /*yield*/, conditionalSleep(this.config.upToDateTimeout)];
                    case 6:
                        _e.sent();
                        return [3 /*break*/, 2];
                    case 7:
                        fromBlock = blockInterval.fromBlock, toBlock = blockInterval.toBlock;
                        this.postStatusUpdate({ type: "processing", fromBlock: fromBlock, toBlock: toBlock, latestBlock: latestBlock, earliestBlock: earliestBlock });
                        (_c = this.config.logger) === null || _c === void 0 ? void 0 : _c.log(this.instanceId, "Process from block", fromBlock, "to block", toBlock);
                        _e.label = 8;
                    case 8:
                        _e.trys.push([8, 11, , 13]);
                        return [4 /*yield*/, this.processBlocks(fromBlock, toBlock)];
                    case 9:
                        _e.sent();
                        this.state.lastIndexedBlock = Math.max(toBlock, this.state.lastIndexedBlock);
                        this.state.earliestIndexedBlock = Math.min(fromBlock, this.state.earliestIndexedBlock);
                        return [4 /*yield*/, conditionalSleep(this.config.syncTimeout)];
                    case 10:
                        _e.sent();
                        return [3 /*break*/, 13];
                    case 11:
                        e_1 = _e.sent();
                        (_d = this.config.logger) === null || _d === void 0 ? void 0 : _d.error(e_1);
                        return [4 /*yield*/, conditionalSleep(this.config.upToDateTimeout)];
                    case 12:
                        _e.sent();
                        return [3 /*break*/, 13];
                    case 13: return [3 /*break*/, 2];
                    case 14:
                        console.log(this.instanceId, "Shutting down");
                        return [2 /*return*/];
                }
            });
        });
    };
    SafeIndexer.prototype.shutdown = function () {
        this.running = false;
    };
    SafeIndexer.prototype.pause = function () {
        this.paused = true;
    };
    SafeIndexer.prototype.resume = function () {
        this.paused = false;
    };
    SafeIndexer.prototype.processBlocks = function (from, to) {
        return __awaiter(this, void 0, void 0, function () {
            var events, interactions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loader.loadEvents(from, to, this.config.safe)];
                    case 1:
                        events = _a.sent();
                        return [4 /*yield*/, this.parser.parse(events)];
                    case 2:
                        interactions = _a.sent();
                        if (interactions.length > 0)
                            try {
                                this.callback.onNewInteractions(interactions);
                            }
                            catch (_b) { }
                        return [2 /*return*/];
                }
            });
        });
    };
    return SafeIndexer;
}());
exports.SafeIndexer = SafeIndexer;
